import React from "react";
import "../../../theme/css/Recycler.css"

export const Recycler = () =>{
    return(
        <>
        <div class="section-container row section-half-background-image-container1">
                <div class="col-md-6 image-column-r">
                    <div className='col-md-2'>
                    </div>
                </div>
                <div class="col-md-6">
                        <div class="text-column">
                            <h2>Réparation</h2>
                            <p>La CyberGrange abrite dans ses locaux, un espace de réparation. Cet atelier est composé d’un pôle de réparation textile, électronique, électroménager, vélo et petit mobilier. En tant qu’adhérant·e de la CyberGrange, vous avez la possibilité de venir réparer vous-même vos objets avec l’aide de l’équipe du fablab.</p>
                            <p>
                                Une fois par mois, l’espace réparation sort de la CyberGrange et vient s’installer au pied des immeubles du quartier Neuhof Meinau pour devenir l’évènement mensuel “Repair-Café”. <br />
                                Lors de ces évènements, vous pouvez venir réparer vos objets gratuitement et partager un café en discutant autour des différents stands de réparation. Vous pouvez également vous joindre à l’équipe de bénévoles et mettre vos talent de réparateur·ice en pratique ! <br />
                                Pour plus d’information ou pour vous joindre à l’équipe, vous pouvez contacter : <a className="link" title="envoyer un e-mail" href='mailto:melanie.mathieu@cybergrange.eu'>Melanie MATHIEU</a><br />
                            </p><br></br>
                            <h5 style={{color:'white'}}>Réemploi</h5>
                            <p>Si vous souhaitez recycler ou réutiliser vos affaires en les détournant de leurs usages principaux ou en les modifiant/personnalisant, vous pouvez venir le faire dans le fablab avec l’aide de l’équipe ou lors d’ateliers dédiés organisés une à deux fois par mois par la CyberGrange (voir le calendrier des activités)</p>
                        </div>
                </div>
        </div>
        </>
    );
}