import Index from "./theme/Index";
import "./theme/css/App.css";

import Header from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import ScrollButton from "./components/ScrollButton";
function App() {
  return (
    <div className="container-fluid m-z p-0">
      <Header />
      <Index />
      <Footer />
    </div>
  );
}
export default App;
