import React from "react";
import { Button, Container } from "react-bootstrap";

export const NewsLetter = () => {
  return (
    <>
      <div className="NewsLetterBox">
        <Container>
          <div className="NewsLetter">
            <div className="NewsLetterTitle">
              <h6>Suivez nos aventures</h6>
              <h2>INSCRIPTION NEWSLETTER</h2>
              <p>
              Vous souhaitez être tenu au courant des actualités de la CyberGrange ?<br></br>
              Laissez-nous vos coordonnées !<br></br>
              <a >Consulter les lettres d'informations précédentes<br></br></a>
              </p>
              <div className="NewsEmail">
                  <div id="mc_embed_signup">
                    <form action="https://cybergrange.us5.list-manage.com/subscribe/post?u=b7a73dcbd6364e83ba290a08d&amp;id=418bfd80d8&amp;f_id=00c653e9f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                          <div id="mc_embed_signup_scroll">
                          <div class="indicates-required"><span class="asterisk">*</span> indique requis</div>
                          <div class="mc-field-group">
                            <label for="mce-EMAIL">Adresse e-mail  <span class="asterisk">*</span></label>
                            <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required />
                          </div>
                          <div id="mce-responses" class="clear foot">
                            <div class="response" id="mce-error-response" style={{display:'none'}}></div>
                            <div class="response" id="mce-success-response" style={{display:"none"}}></div>
                          </div>
                          <div class="optionalParent">
                              <div class="clear foot">
                                  <input type="submit" value="Inscription" name="subscribe" id="mc-embedded-subscribe" class="button"/>
                              </div>
                          </div>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* <!-- Begin Mailchimp Signup Form --> */}    </>
  );
};
