import "../theme/css/Agenda.scss";
import React from 'react';
import Modal from 'react-bootstrap/Modal';

export default function AgendaModal({ show, setShow, Agenda }) {
    const handleClose = () => {
        setShow(false)
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header className='bg-white' closeButton>
            </Modal.Header>
            <Modal.Body className='bg-white'>
                <div>
                    <div>
                        {/* <a href="#" title="Fermer" className="modal-close">
                            <i className="fas fa-close"></i>
                        </a> */}
                        <h3><Modal.Title className="modal-title">{Agenda?.attributes.titre1}</Modal.Title></h3>
                        <hr className="hr-modal"></hr>
                        <p>
                            <b>Description atelier: </b>
                            {Agenda?.attributes.description}
                            <br />
                            <b>Thème:</b>
                            {Agenda?.attributes.theme}
                            <br />
                            <b>Lieu:</b>
                            {Agenda?.attributes.lieu}
                            <br />
                            <b>Temps:</b> {Agenda?.attributes.temps}
                            <br />
                            <b>Tranche d'âge:</b> {Agenda?.attributes.age}
                            <br />
                            <b>Se Concentrer:</b> {Agenda?.attributes.seConcentrer}
                            <br />
                            <b>Conditions:</b> {Agenda?.attributes.conditions}{" "}
                            <br />
                            <b>Matériaux:</b> {Agenda?.attributes.materiaux}
                            <br />
                            <b>Participants:</b>
                            {Agenda?.attributes.participants}
                            <br />
                            <b>Prix: </b>
                            {Agenda?.attributes.prix}{" "}
                        </p>
                        <br />

                        <div>
                            <small>Consulter pour l'inscription 👇</small>
                        </div>
                        <div
                            className="inscris"
                            href="#open-inscris"
                            target="_blank"
                        >
                            cliquez sur "Inscription" sur la page de l'agenda!
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}