import React, {useEffect, useRef, useState } from 'react';
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBRow,
  MDBCol,
} from 'mdb-react-ui-kit';
import '../../../theme/css/Actualites.scss'
import axios from 'axios';
import _ from 'lodash';
import { Container, Pagination } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const PageSize = 8;
const  url = process.env.REACT_APP_BASE_URL;


export const Actualites = () => {
    const [actualites, setActualites] = useState([])
    const [tableData, setTableData] = useState([]);
    const getActualite = async() => {
        const data = await axios.get(`${url}/api/Actualites?sort[0]=id&populate=*`)
        setActualites(data.data.data)
        console.log(data)
    };
    useEffect(()=>{
        getActualite()
    } , [])


    const pageCount = actualites ? Math.ceil(actualites.length/PageSize):0; 
    const pages = _.range(1, pageCount+1)
    console.log('fgh', actualites)
    const paginate = (array, page_size, page_number) => {
        let page = page_number - 1;
        return array.slice(page * page_size, page * page_size + page_size);
        };
        const handlePage = (value) => {
        setTableData(paginate(actualites, PageSize, value));
        };
    useEffect(() => {
        if (!actualites) {
            return;
        }
        setTableData(paginate(actualites, PageSize, 1));
    }, [actualites]);

    const handleDataRecordClick = (id) => {
        window.location.href = `/actualites/${id}`;
      };

  return (
    <>
        <div className="BreadCrumb">
            <Container>
            <div className="Bread-Crumb">
                <h1><i className='far fa-newspaper fa-color-r'></i> Actualités</h1>
                <div className="Bread-Crumb-text">
                <Link to="/home" className="home-page">
                Accueil
                </Link>
                <h6>/</h6>
                <Link to="/Actualites" className="Current-page">
                Actualités
                </Link>
                </div>
            </div>
            </Container>
        </div>
        <div className='container news'>
            <p className='text-p'>Vous pouvez également vous abonner 
                 à notre newsletter pour recevoir les dernières actualités directement dans votre boîte de réception.</p>
            <MDBRow className='row-cols-1 row-cols-md-4 g-4 '>
            {tableData?.length >0 && tableData.map((Actualite)=>(
            <MDBCol key={Actualite.id} onClick={() => handleDataRecordClick(Actualite.id)}>
                <MDBCard className='h-100 card-col'>
                <MDBCardImage
                className='news-img'
                    src={Actualite?.attributes?.image?.data?.attributes?.url}
                    alt='...'
                    position='top'
                />
                <MDBCardBody className='figcaption'>
                    <MDBCardTitle className='news-title'>{Actualite.attributes.titre}</MDBCardTitle>
                    <MDBCardText className='news-txt'>
                        <p>{Actualite.attributes.soustitre}<br/></p>
                        <p>{Actualite.attributes.date}<br/></p>
                    </MDBCardText>
                </MDBCardBody>
                <MDBCardFooter>
                    <div class="interior">
                        <a class="news-btn">Lire plus ...</a>
                    </div>
                    <div id="info" class="modal-window">
                        <div>
                            <a href="#" title="Fermer" class="modal-close"><i className='fas fa-close'></i></a>
                            <h1>{Actualite.attributes.titre}</h1>
                            <p>
                                <b>Description atelier: </b>{Actualite.attributes.description}<br />
                            </p><br/>
                        </div>
                    </div>
                </MDBCardFooter>
                </MDBCard>
            </MDBCol>
            ))}
            </MDBRow> 
            <nav aria-label="Page navigation example" className='Page d-flex flex-row'>
                <ul class="pagination justify-content-end">
                    {
                        pages.map((page) => {
                            return <li onClick={() => handlePage(page)} class="page-item"><a class="page-link" href="#">Page {page}</a></li>
                        })
                    }
                </ul>
            </nav>
        </div>
    </>
  );
}