import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import DefalutScrollToTop from "./components/pages/DefaultScrollToTop";
import "./theme/css/index.css";

const root = createRoot(document.getElementById("root"));
root.render(
  <Router>
    <DefalutScrollToTop />
    <App />
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
