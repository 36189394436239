import React from 'react'
import { HomeBanner } from './HomeBanner/HomeBanner'
import { Learing } from './Learing/Learing'
import { AgendaSlider } from './AgendaSlider/AgendaSlider'
import { NewsLetter } from './NewsLetter/NewsLetter'
import "../../../theme/css/Home.css"
import { Services } from './Services/Services'

export const Home = () => {
    return (
        <div>
            <HomeBanner/>
            <Learing/>
            <div className="Distance-Img">
            </div>
            <Services />
            <AgendaSlider />
            <NewsLetter/>
        </div>
    )
}
