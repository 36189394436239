import React from "react";
import img1 from "../../../../assets/Banner/HeaderImg.png";
import img2 from "../../../../assets/Banner/HeaderImg2.png";
import img3 from "../../../../assets/Banner/HeaderImg3.png";
import img4 from "../../../../assets/Banner/HeaderImg4.png";
import img5 from "../../../../assets/Banner/HeaderImg5.png";
import "../../../../theme/css/Slider.css";

// import { HomeBannerBox } from "./HomeBannerBox";

export const HomeBanner = () => {
  return (
    <>
      <div>
        <div className="BannerImg Title mx-auto">
          <div id="captioned-gallery">
            <figure className="slider">
              <figure>
                <img src={img1} alt="" />
              </figure>
              <figure>
                <img src={img2} alt="" />
              </figure>
              <figure>
                <img src={img3} alt="" />
              </figure>
              <figure>
                <img src={img4} alt="" />
              </figure>
              <figure>
                <img src={img5} alt="" />
              </figure>
            </figure>
          </div>
        </div>
      </div>
    </>
  );
};
