import React, { useEffect } from "react";
import "../../../theme/css/Apprendre.css";
import img1 from "../../../assets/Apprendre/img1.jpg";
import g1 from "../../../assets/Apprendre/gallery1.png";
import g2 from "../../../assets/Apprendre/gallery2.png";
import g3 from "../../../assets/Apprendre/gallery3.png";
import g4 from "../../../assets/Apprendre/gallery4.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container } from "react-bootstrap";

export const Apprendre = () => {
  useEffect(() => {
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  });

  return (
    <>
      <div className="BreadCrumb" style={{ paddingBottom: "30px" }}>
        <Container>
          <div className="Bread-Crumb">
            <div className="Bread-Crumb-text" style={{ paddingBottom: "30px" }}>
              <h1> Médiation numérique</h1>
              <h3 className="head3">Apprendre</h3>
              <p className="dark-gray">
                <b>
                  La CyberGrange vous accompagne jusqu’à l’autonomie dans les
                  usages quotidiens des technologies, services et médias
                  numériques.
                </b>
              </p>
            </div>
            <div class="content-ap">
              <div class="col">
                <img src={img1} alt="Image" />
              </div>
              <div class="col text-d">
                <p style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                  Dans un monde où le numérique a pris une place importante, une
                  maîtrise minimale des outils technologiques (ordinateur,
                  téléphone mobile, guichets et bornes automatiques, ...), une
                  culture générale et des acquis sur le numérique (principes de
                  fonctionnement des réseaux, règles éthiques et juridiques,
                  ...) sont nécessaires pour exercer pleinement sa citoyenneté.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="col-bg">
        <div className="container">
          <div class="row ">
            <div class="col-lg-12 row row-app">
              <div className="col-lg-12">
                <h3 className="h3">
                  Je m’intéresse <b>au numérique</b>
                </h3>
              </div>
              <div className="col col-md-6 ">
                <h5>
                  <b>Être accompagné</b>
                </h5>
                <p className="paragraph-app">
                Notre équipe vous propose une permanence d’aide à la réalisation de
                  <b> démarches administratives en ligne</b> (CAF, pôle emploi, impôts.gouv, etc.).<br></br>
                  Tous les lundis de 14h-17h & les vendredis de 9h-12h.  
                  <br />
                </p>
          
                <p className="paragraph-app"><b className="mt-4">Sans rendez-vous </b><br></br>
                <a className="btn cbtn" href="/contact">
                    <b>Accès</b>
                  </a>
                </p>
              </div>
              <div className="col-lg-6">
                <h5>
                  <b>Se former</b>
                </h5>
                <p className="paragraph-app">
                  Les ateliers{" "}
                  <b>d’initiations au numérique individuel ou collectif</b>, sont
                  organisés afin de se familiariser avec l’environnement
                  informatique.
                  <br />
                  Ils permettent <b>d’apprendre</b> les rudiments du maniement
                  d’un ordinateur, de savoir utiliser une adresse mail, de
                  manipuler Word ou Excel, de découvrir des logiciels, de
                  comprendre les enjeux de la sécurité sur internet…{" "}
                </p>
                <p className="paragraph-app">
                  <b>Inscription gratuite</b> <br />
                  <a className="btn cbtn" href="https://linktr.ee/cybergrange" target="_blank">
                    <b>Prendre un RDV</b>
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-12 row row-app">
            <div className="col-md-6">
                <h5>
                  <b>Découvrir</b>
                </h5>
                <p className="paragraph-app">
                  Le CODE CLUB est une{" "}
                  <b>
                    activité d’initiation à la programmation et à la robotique
                  </b>{" "}
                  pour les enfants de 9 ans à 13 ans, tous les mercredis de 14h à
                  16h.
                </p>
                <p className="paragraph-app"><b>Inscription gratuite</b><br></br>
                  <a className="btn cbtn" href="/agenda">
                    <b className="mt-4">Inscription</b>
                  </a>
                </p>
              </div>
              <div className="col-md-6">
                <h5>
                  <b>Entreprendre</b>
                </h5>
                <p className="paragraph-app">
                  Le <b>coup de pouce numérique entrepreneur </b>est un dispositif
                  d'accompagnement des entrepreneurs et des porteurs de projet à
                  la <b>digitalisation de leur activité.</b>
                  <br />
                  Nous proposons un accompagnement individuel et collectif des
                  publics habitants en QPV, à la <b>conception graphique </b>
                  (logo, identité visuelle, flyer,...) et à la{" "}
                  <b>communication digitale </b>(site web, réseaux sociaux,...,).
                  <br />
                </p>
                <p className="paragraph-app">
                <b className="mt-4">Inscription gratuite</b> <br />{" "}
                  <a className="btn cbtn" href="https://www.bit.ly/cybergrangeCPNE">
                    <b>En savoir plus</b>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dark row">
        <div class="content-ap2 container">
          <div class="col">
            <h4>
              Je suis{" "}
              <b>
                un professionnel, une entreprise, une association, une école…
              </b>
            </h4>
            <p className="paragraph-app">
              Pour tout projet, nous vous invitons à <b>nous contacter</b> pour
              vous accompagner et vous informer sur l’ensemble de nos
              prestations.
              <br />
              Des ateliers de découverte, des formations ou des projets
              collectifs peuvent être mis en place sur mesure. Vous pouvez
              retrouver nos propositions sur notre <a className="link" href="https://www.calameo.com/read/00714167268eda3a56815" target="_blank">catalogue</a>.
              <br />
              <br />
              Pour prendre un rendez-vous ou nous adresser votre demande :<br />
              au <a href="tel:+33771894687"><b>07 71 89 46 87</b></a> ou par mail à{" "}
              <a className="link" href="mailto:contact@cybergrange.eu">
                contact@cybergrange.eu
              </a>
              <br />
            </p>
            <p className="paragraph-app">
              <a className="btn cbtn" style={{marginTop:'7px'}} href="/contact">
                <b>Contact</b>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="Career-gallary">
        <img src={g1} />
        <img src={g2} />
        <img src={g3} />
        <img src={g4} />
      </div>
    </>
  );
};