import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap-v5";
import { Link } from "react-router-dom";
import img1 from "../../../assets/OnlineLearing/OLearing.png";
import AOS from "aos";
import "aos/dist/aos.css";

export const Qui = () => {
    useEffect(() => {
      AOS.init({
        duration: 3000,
      });
    });
  
    return (
      <>
        <div className="BreadCrumb" >
          <Container>
          <div className="Bread-Crumb">
              <h1>Qui sommes-nous ?</h1>
              <div className="Bread-Crumb-text">
              <Link to="/home" className="home-page">
              Accueil
              </Link>
              <h6>/</h6>
              <Link to="/qui" className="Current-page">
              Qui sommes-nous ?
              </Link>
              </div>
          </div>
          </Container>
        </div>      
        <div style={{paddingTop:'50px'}}>
          <Container>
            <div className="Online-learnig-Content text-align-center"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                    onScroll="AOS">
              <Row className="OLearing-section">
                <Col xl={7} lg={7} md={7} sm={7}>
                  <div>
                    <h4 className="h-left">Qui sommes-nous ?</h4>
                    <div className="p-content">
                      <p>
                      Nous sommes un Tiers-Lieu dédié à l'inclusion 
                      numérique au cœur des quartiers Neuhof et Meinau.
                      </p>
                      <p>
                      La Cybergrange est tout à la fois lieu de formation tous azimuts,
                      point d’accès aux droits, espaces de loisirs numériques et Fablab solidaire.
                      </p>
                      <p>
                      Elle a été imaginée par un collectif de partenaires en 2019:
                      le Centre Social et Culturel du Neuhof, le collège Solignac,
                        l’équipe de <a className="link" target="_blank" href="https://www.jeep.asso.fr/">prévention </a>spécialisée de la Jeep, FACE Alsace, Form'maker,
                        l'école de formation de développeurs web “Access code School”, l'entreprise Neuronality, Lupovino, le projet FOCALE PIC 100% inclusion pour accompagner 400 personnes à la recherche d'un emploi,
                          l’organisme de formation CPCV Est, la <a className="link" target="_blank" href="https://associationlaresu.com/">RESU </a>. 
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={5} lg={5} md={5} sm={5}>
                  <img src={img1} alt="OLearing" className="mx-auto img-fluid img-nos" />
                </Col>
                <Row className="Online-learnig-Content text-align-center">
                    <div className="p-content">
                    <p>
                      Labellisé <a className="link" href="https://agence-cohesion-territoires.gouv.fr/fabriques-de-territoire-582" target="_blank">“Fabrique numérique de territoire”</a> dans le cadre du programme national “Nouveaux Lieux, Nouveaux Liens”, notre Tiers-Lieu accompagne les habitants du Neuhof, de la Meinau et encore plus dans quatre domaines: 
                    </p>
                    <p>
                    <b>-la formation et l’accès à l’emploi, l’accès aux droits</b> dans un contexte de dématérialisation des démarches des services publics (comme la CPAM, la CAF, Pôle emploi…), 
                    </p>
                    <p>
                    -<b>la vie quotidienne</b> (comme l’équipement informatique à domicile, l’école à la maison, l’apprentissage du Français, la gestion des temps d’écrans avec les enfants, les relations sociales numériques sur les réseaux sociaux, la e-consommation…) 
                    </p>
                    <p>
                    <b>-les loisirs créatifs et récréatifs numériques</b> (jeux vidéo, apprentissage du code, expérimentation, fabrication, création d’objets…).
                    </p>
                    <p>
                    <b>-le réemploi et le bricolage</b> (aide à la réparation d’objet du quotidien, zone de gratuité, lieu de conseil…)
                    </p>
                    <p><br></br>
                    En 2022, nous avons écrit, avec nos partenaires et soutiens, notre projet social pour les années à venir : 
                    </p>
                    <p>
                    En 2023 (et après !), la Cybergrange veut être…
                    </p>
                    <p>
                    "Une structure d'intervention technique et sociale qui répond à des besoins,
                     identifiés par ses partenaires ou dont elle s'empare,
                      en s'appuyant sur des acteurs qui partagent ses valeurs et sur ses compétences propres,
                       dans les champs du numérique, de l'emploi et de la formation, de la fabrication numérique, 
                       du bricolage, du réemploi et de l'économie circulaire. Elle se veut un outil de résilience 
                       et d'excellence pour son territoire.
                        C'est un écosystème apprenant, hybride et critique.
                    </p>
                    <p>
                    Elle anime un réseau d'individus et d'organisations par 
                    la construction de démarches expérimentales, de projets 
                    collectifs, et la mise en place d'espaces de réflexion critique, 
                    de questionnements et de partage d'expertises.
                    </p>
                    <p>
                    Les méthodes, dispositifs, projets et démarches qu'elle conçoit sont des communs : 
                    ils sont partagés afin d'être essaimés, transformés, et réutilisés.
                    </p>
                    <p>
                    Elle partage avec un ensemble d'organisations un souhait de transformation sociale 
                    basée sur la croyance profonde dans le fait que tous les individus sont capables 
                    de réussite, que l'accès aux cultures de l'innovation technique et sociale est un droit, 
                    et que c'est par la créativité et la solidarité que doivent être résolus nos défis communs : 
                    la crise climatique, la surexploitation des ressources, l'exclusion sociale, la transition 
                    vers un numérique au service de l'intérêt général, les inégalités de pouvoir, de savoir et de trajectoire.
                    </p>
                    <p>
                    Ses valeurs et son mode de fonctionnement la définissent comme un 
                    Tiers-Lieux, mouvement dans lequel, elle s'implique et dont elle partage les ambitions."
                    </p><br></br><br></br><br></br>
                    </div>
                </Row>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  };