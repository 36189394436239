import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import "../../../theme/css/Agenda.scss";
import "../../../theme/css/Home.css";
import axios from "axios";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import AgendaModal from "../../AgendaModal";
import { format, parseISO } from 'date-fns';

const PageSize = 8;
const url = process.env.REACT_APP_BASE_URL;


const validateSchema = Yup.object({
  prenom: Yup.string().typeError("Requis").required("Requis"),
  nom: Yup.string().typeError("Requis").required("Requis"),
  email: Yup.string()
    .typeError("Requis")
    .email("Email invalide")
    .required("Requis"),
  telephone: Yup.string()
    .typeError("Requis")
    .required("Requis")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Numéro de téléphone invalide"
    ),
});

export const Agenda = () => {
  const formik = useFormik({
    initialValues: {
      prenom: "",
      nom: "",
      telephone: "",
      email: "",
    },
    validationSchema: validateSchema,

    onSubmit: async (values, { resetForm }) => {
      try {
        const data = await axios.post(`${url}/api/inscrises`, {
          data: { ...values, agenda },
        });
        if (data.status === 200) {
          getAgenda();
        }
        setInscris({});
        resetForm();
        // console.log("inscrises", values);
      }
      catch (e) {

      }
    }
  });

  const [agendas, setAgendas] = useState([]);
  const [agenda, setAgenda] = useState();
  const [selectedAgenda, setSelectedAgenda] = useState();
  const [show, setShow] = useState(false);
  

  const [tableData, setTableData] = useState([]);
  const [inscris, setInscris] = useState();
  const getAgenda = async () => {
    const data = await axios.get(`${url}/api/agendas?sort[0]=id%3Adesc&populate=*`);
    setAgendas(data.data.data);
    console.log(data);
  };
  useEffect(() => {
    getAgenda();
    console.log(agendas);
  }, []);


  const pageCount = agendas ? Math.ceil(agendas.length / PageSize) : 0;
  const pages = _.range(1, pageCount + 1);
  const paginate = (array, page_size, page_number) => {
    let page = page_number - 1;
    return array.slice(page * page_size, page * page_size + page_size);
  };
  const handlePage = (value) => {
    setTableData(paginate(agendas, PageSize, value));
  };
  useEffect(() => {
    if (!agendas) {
      return;
    }
    setTableData(paginate(agendas, PageSize, 1));
  }, [agendas]);
  console.log(selectedAgenda);

  const handleOpen = (Agenda) => {
    setSelectedAgenda(Agenda)
    setShow(true)
  }
  return (
    <>
      <div className="BreadCrumb">
        <Container>
          <div className="Bread-Crumb">
            <h1>
              <i className="fas fa-calendar-alt fa-color-r"></i> Agenda des
              activités et inscriptions
            </h1>
            <div className="Bread-Crumb-text">
              <Link to="/home" className="home-page">
                Accueil
              </Link>
              <h6>/</h6>
              <Link to="/agenda" className="Current-page">
                Agenda
              </Link>
            </div>
          </div>
        </Container>
      </div>

      <div className="container agenda">
        <MDBRow className="row-cols-1 row-cols-md-5 g-4">
          {tableData?.length > 0 &&
            tableData.map((Agenda, index) => (
              <MDBCol className="agenda-card" key={index}>
                <MDBCard className="card-col h-100">
                  <MDBCardImage
                    className="agenda-img"
                    src={Agenda?.attributes.image?.data.attributes.url}
                    alt="..."
                    position="top"
                  />
                  <MDBCardBody>
                    <MDBCardTitle className="agenda-title">
                      {Agenda.attributes.titre1}
                    </MDBCardTitle>
                    <MDBCardText className="agenda-txt">
                      <b>Date :</b> {format(parseISO(Agenda.attributes.date), 'dd/MM/yyyy')}
                      <br />
                      <b>Heure :</b> {Agenda.attributes.heure}
                      <br />
                      <b>Durée :</b> {Agenda.attributes.duree}
                      <br />
                    </MDBCardText>
                    <div className="interior">
                      {Agenda.attributes.inscris?.data.length <
                        Agenda.attributes.participants ? (
                        <a
                          className="button-ins"
                          href="#open-inscris"
                          onClick={() => setAgenda(Agenda.id)}
                        >
                          Inscription
                        </a>
                      ) : (
                        <div className="small-note">* Pas de Place</div>
                      )}
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                      <div id="open-inscris" className="modal-window">
                        <div>
                          <a href="#" title="Fermer" className="modal-close">
                            <i className="fas fa-close"></i>
                          </a>
                          <h1>Inscription</h1>
                          <hr className="hr-modal"></hr>
                          <input
                            className="modal-input"
                            name="prenom"
                            type="text"
                            placeholder="Prenom"
                            onChange={formik.handleChange}
                            value={formik.values?.prenom || ""}>
                          </input>
                          {formik.touched?.prenom && formik.errors?.prenom && (
                            <div className="alert alert-danger p-1 mt-1">
                              {formik.errors?.prenom}
                            </div>
                          )}
                          <br />
                          <input
                            className="modal-input"
                            name="nom"
                            type="text"
                            placeholder="Nom"
                            onChange={formik.handleChange}
                            value={formik.values?.nom || ""}
                          ></input>
                          {formik.touched?.nom && formik.errors?.nom && (
                            <div className="alert alert-danger p-1 mt-1">
                              {formik.errors?.nom}
                            </div>
                          )}
                          <br />
                          <input
                            className="modal-input"
                            name="telephone"
                            type="text"
                            placeholder="Telephone"
                            onChange={formik.handleChange}
                            value={formik.values?.telephone || ""}
                          ></input>
                          {formik.touched?.telephone && formik.errors?.telephone && (
                            <div className="alert alert-danger p-1 mt-1">
                              {formik.errors?.telephone}
                            </div>
                          )}
                          <br />
                          <input
                            className="modal-input"
                            name="email"
                            type="Email"
                            placeholder="Email"
                            onChange={formik.handleChange}
                            value={formik.values?.email || ""}
                          ></input>
                          {formik.touched?.email && formik.errors?.email && (
                            <div className="alert alert-danger p-1 mt-1">
                              {formik.errors?.email}
                            </div>
                          )}
                          <br />
                          <button
                            className="cbtn btn"
                            type="submit"
                            data-dismiss="modal"
                          >
                            Inscris
                          </button>
                        </div>
                      </div>
                    </form>
                  </MDBCardBody>
                  <MDBCardFooter>
                    <div className="interior" >
                      <a className="agenda-btn" onClick={() => handleOpen(Agenda)}>
                        En savoir plus
                      </a>
                    </div>
                  </MDBCardFooter>
                </MDBCard>
              </MDBCol>
            ))}
        </MDBRow>
        <AgendaModal show={show} setShow={setShow} Agenda={selectedAgenda} />
        <nav
          aria-label="Page navigation example"
          className="Page d-flex flex-row"
        >
          <ul className="pagination justify-content-end">
            {pages.map((page) => {
              return (
                <li onClick={() => handlePage(page)} className="page-item">
                  <a className="page-link" href="#">
                    Page {page}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </>
  );
};