import React, { useEffect } from "react";
import "../../../theme/css/creer.scss";
import img1 from "../../../assets/Creer/img-1.jpg";
import { Container } from "react-bootstrap";
import g1 from "../../../assets/Creer/gallery1.png";
import g2 from "../../../assets/Creer/gallery2.png";
import g3 from "../../../assets/Creer/gallery3.png";
import g4 from "../../../assets/Creer/gallery4.png";
import g5 from "../../../assets/Creer/gallery5.png";
import g6 from "../../../assets/Creer/gallery6.png";
import g7 from "../../../assets/Creer/gallery7.png";
import g8 from "../../../assets/Creer/gallery8.png";

import AOS from "aos";
import "aos/dist/aos.css";
import { Row, Col, Card } from "react-bootstrap-v5";

export const Creer = () => {
  useEffect(() => {
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  });
  return (
    <>
      <div className="BreadCrumb" style={{ paddingBottom: "30px" }}>
        <Container>
          <div className="Bread-Crumb">
            <div className="Bread-Crumb-text" style={{ paddingBottom: "30px" }}>
              <h1> FABLAB</h1>
              <h3 className="head3">Créer</h3>
            </div>
            <div class="content-ap">
              <div class="col">
                <img src={img1} alt="Image" />
              </div>
              <div class="col text-d">
                <p className="center" style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                  Le fablab de la Cybergrange est un espace de création et de réparation, équipé de machines à commande numérique et d’outils classiques. Vous pouvez y venir lors d’ateliers, ou en autonomie, en vous abonnant et en réservant des créneaux d’utilisation des machines ! 
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    
      <div className="dark-c container">
        <div class="content-ap2">
          <div class="creer">
            <h4>
              Je suis un{" "}
              <b>professionnel, une entreprise, une association, une école</b>{" "}
              ...
            </h4>
            <p className="text-creer">
              Pour tout projet, nous vous invitons à <b>nous contacter</b> pour
              vous accompagner et vous informer sur l’ensemble de nos
              prestations.
              <br />
              Des ateliers de découverte, des formations ou des projets
              collectifs peuvent être mis en place sur mesure. Vous pouvez
              retrouver nos propositions sur notre <a class="link" href="https://www.calameo.com/read/00714167268eda3a56815" target="_blank">catalogue</a>.
              <br />
              <br />
              Pour prendre un rendez-vous ou nous adresser votre demande :<br />
              au <a href="tel:+33771894687"><b>07 71 89 46 87</b></a> ou par mail à{" "}
              <a  className="link" href="mailto:contact@cybergrange.eu" target="_blank">
                contact@cybergrange.eu
              </a>
              <br />
            </p>
            <p className="paragraph-app">
              <b>Inscription gratuite sur rendez-vous</b> <br />{" "}
              <a
                className="btn cbtn"
                href="/contact"
                target="_blank"
              >
                <b>Contact</b>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="dark-c container">
        <div class="content-ap2">
          <div class="creer">
            <h4>
              Je suis un.e <b>passionné.e du bricolage</b>
            </h4>
            <p className="text-creer">
              Le fablab peut être utilisé par tou-tes, à partir de 12 ans, en{" "}
              <b>autonomie</b> avec un <b>abonnement annuel</b>, en réservant
              des créneaux sur une machine à laquelle vous vous êtes formés (par
              un <b>pass machine</b>).
              <br />
              Des <b>ateliers de découverte </b>que vous pouvez retrouver sur
              notre{" "}
              <a className="link" href="/agenda" target="_blank">
                agenda{" "}
              </a>
              sont également proposés régulièrement pour les jeunes et les moins
              jeunes.
              <br />
              Les machines peuvent être réservées et utilisées les lundi, mardi,
              jeudi et vendredi de 9h à 12h et de 13h à 17h et les mercredi de
              9h à 12h et de 13h à 19h
              <br />
              Chaque mercredi, de 13 à 19h, c’est le{" "}
              <a className="link" href="/agenda" target="_blank">
                “fablab ouvert”{" "}
              </a>
              : le moment où nos usagers peuvent se rencontrer, échanger sur
              leurs projets entre eux et avec l’équipe.
              <br />
            </p>
            <p className="paragraph-app">
              <b> Pour consulter nos tarifs :</b><br />{" "}
                <a
                  className="btn cbtn"
                  href="https://docs.google.com/document/d/134yFBNAo5KH3sgHid19E9gfYxuzCI6l5hEpAFplKUvc/edit?usp=share_link"
                  target="_blank" 
                >
                  <b>Tarifs</b>
                </a>
            </p>
          </div>
        </div><br></br><br></br>
      </div>
      <div className="dark-third">
        <div class="content-ap2 container">
          <div class="col creer">
            <h4>Nos équipements :</h4>
            <p className="text-creer2">
              - 8 imprimantes 3D
              <br />
              - 2 découpes laser
              <br />
              - 2 découpes vinyle
              <br />
              - 2 brodeuses numériques
              <br />
              - 1 thermoformeuse
              <br />
              - 1 fraiseuse à commande numérique
              <br />
              - 1 injecteuse résine
              <br />
              - 1 scanner 3D <br />
              - 8 kits de programmation Arduino
              <br />- Matériel de bricolage
            </p>
            <p className="paragraph-app">
              <a
                className="btn cbtn"
                href="https://fablabcybergrange.my.canva.site/"
                target="_blank" 
              >
                <b>Réserver une machine</b>
              </a>
          </p>
          </div>
        </div>
      </div>
      <div className="Career-gallary">
        <img src={g1} />
        <img src={g2} />
        <img src={g3} />
        <img src={g4} />
        <img src={g5} />
        <img src={g6} />
        <img src={g7} />
        <img src={g8} />
      </div>
    </>
  );
};