import React, { useState } from "react";
import { Row, Col, Collapse, Container, Button } from "react-bootstrap";
import { GrFacebookOption } from "react-icons/gr";
import { FaLinkedinIn, FaInstagramSquare } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import logo2 from "../../../src/assets/Logo/White-Logo2.png";
import { Link } from "react-router-dom";
import ScrollButton from "../ScrollButton";

export const Footer = () => {
  const [Useopen, UsesetOpen] = useState(false);
  const [Sopen, SsetOpen] = useState(false);
  const [Copen, CsetOpen] = useState(false);
  return (
    <>
      <div className="Footer position-relative">
        <Container>
          <Row className="Footerbox">
            <Col xl={4} lg={4} md={4}>
              <div className="F-col-1 footer-text">
                <div>
                  <h2>
                    {""}
                    <img
                      src={logo2}
                      alt="logo" style={{width:'50%'}}
                      className="mx-auto img-fluid"
                    />{" "}
                  </h2>
                </div>
                <br></br>
                <ul className="list-unstyled social-icon">
                  <li className="d-inline-block ">
                    <a
                      href="https://www.facebook.com/cybergrange/"
                      className="d-block"
                    >
                      <i>
                        <GrFacebookOption />
                      </i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      href="https://www.linkedin.com/company/cybergrange/mycompany/"
                      className="d-block"
                    >
                      <i>
                        <FaLinkedinIn />
                      </i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      href="https://www.instagram.com/lacybergrange/?hl=fr"
                      className="d-block"
                    >
                      <i>
                        <FaInstagramSquare />
                      </i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      href="https://twitter.com/cybergrange"
                      className="d-block"
                    >
                      <i>
                        <BsTwitter />
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={2} lg={2} md={2}>
              <div className="F-col-2 footer-text FooterVertical">
                <h2 className="footerLinkHeading">
                  Nos Liens
                  <Button
                    className="Footer-button"
                    onClick={() => UsesetOpen(!Useopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Useopen}
                  ></Button>
                </h2>
                <Collapse in={Useopen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/home">Accueil</Link>
                    </li>
                    <li>
                      <Link to="/Actualites">Actualités</Link>
                    </li>
                    <li>
                      <Link to="/ressource">Ressources</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col>
            <Col xl={2} lg={2} md={2}>
              <div className="F-col-3 footer-text FooterVertical">
                <h2 className="footerLinkHeading">
                  Nos pôles
                  <Button
                    className="Footer-button"
                    onClick={() => SsetOpen(!Sopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Sopen}
                  ></Button>
                </h2>
                <Collapse in={Sopen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/apprendre">Apprendre</Link>
                    </li>
                    <li>
                      <Link to="/creer">Créer</Link>
                    </li>
                    <li>
                      <Link to="/recycler">Réparer</Link>
                    </li>
                    <li>
                      <Link to="/se-former">Se former</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4}>
              <div className="F-col-4 footer-text FooterVertical">
                <h2 className="footerLinkHeading">
                  Nous Contacter
                  <Button
                    className="Footer-button"
                    onClick={() => CsetOpen(!Copen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Copen}
                  ></Button>{" "}
                </h2>
                <Collapse in={Copen}>
                  <ul className="list-unstyled">
                    <li>
                      <span>
                        2 Rue du Commandant François<br />
                        Quartier du Neuhof<br />
                        67100 STRASBOURG{" "}
                      </span>
                    </li>
                    <li>
                      <span><a href="tel:+33771894687">07.71.89.46.87</a></span>
                      
                    </li>
                    <li>
                      <span><a href="mailto:contact@cybergrange.eu">contact@cybergrange.eu</a></span>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col>
          </Row>
          <div className="Footer-bottom">
            <h6 className="F-policy">
              {" "}
              <a href="terms" target="_blank">
              Mentions légales
              </a>{" "}
            </h6>
            <h6 className="F-copywrite">
              Copyright © 2023 La CyberGrange . Tous les droits sont réservés
            </h6>
          </div>
          <ScrollButton />
        </Container>
      </div>
    </>
  );
};
