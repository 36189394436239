import React from "react";
import { Container, Row, Col, Card} from "react-bootstrap";
import { RessourceData } from "./RessourceData";
import { Link } from 'react-router-dom';

export const RessourceArea = () => {
  return (
    <>
      <div className="BreadCrumb">
        <Container>
        <div className="Bread-Crumb">
            <h1><i class="fa fa-database fa-color-r"></i> Ressources</h1>
            <div className="Bread-Crumb-text">
            <Link to="/home" className="home-page">
            Accueil
            </Link>
            <h6>/</h6>
            <Link to="/ressource" className="Current-page">
            Ressources
            </Link>
            </div>
        </div>
        </Container>
      </div>

      <Container>
        <div className="blog">
        <br></br>
          <br></br>
          <br></br>
          <Row>
            {RessourceData.map((elm) => (
              <Col  xl={4} lg={4} md={4} sm={6} xs={12} key={elm.key}>
                <div className="Blog-card" title="Visitez le site web pour plus d'informations.">
                    <Card className=" r-card">
                      <div className="Blog-img">
                        <div className="Blog-icon ">
                          <Card.Img
                            variant="top"
                            src={elm.image}
                            className="mx-auto img-fluid"
                          />
                        </div>
                      </div>
                      <Card.Body>
                        <Card.Title>{elm.title}</Card.Title>
                        <Card.Text>{elm.text}
                        <hr className="hr-tag" />
                        <p className="paragraph-r">{elm.p}</p>
                        </Card.Text>
                        <a className="blog-a" href={elm.link} target='_blank'>Site Web du " {elm.title} " </a>
                      </Card.Body>
                    </Card>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </>
  );
};