import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap-v5";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import "../../../../theme/css/Agenda.scss";
import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

export const AgendaSlider = () => {
  const [agendas, setAgendas] = useState([]);
  const getAgenda = async () => {
    const data = await axios.get(`${url}/api/agendas?sort[0]=id&populate=image`);
    setAgendas(data.data.data);
    console.log(data);
  };
  useEffect(() => {
    getAgenda();
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  });

  return (
    <>
      <div className="Title" style={{marginTop:'60px'}}>
        <Container>
          <div className="Title">
            <Row className="">
              <Col xl={4} lg={4} md={4} sm={4}>
                <div
                  className="Online-learnig-Content text-align-center">
                  <h4 className="h-left" id="agenda-sider">
                    ~ Agenda
                  </h4>
                  <p> 
                  Vous pouvez retrouver nos activités et ateliers sur notre agenda et vous y inscrire. À bientôt ! 
                  </p>
                  <br></br>
                  <a href="../../agenda" className="link" target="_blank">
                    En savoir plus ...{" "}<br></br><br></br>
                  </a>
                </div>
              </Col>
              {agendas?.length > 0 &&
                agendas.slice(0, 4).map((Agenda) => (
                  <Col xl={2} lg={2} md={2} sm={2}>
                    <MDBCol className="h-100">
                      <MDBCard className="h-100">
                        <MDBCardImage
                          className="agenda-img"
                          src={Agenda.attributes.image?.data.attributes.url}
                          alt="..."
                          position="top"
                        />
                        <MDBCardBody>
                          <MDBCardTitle className="agenda-title">
                            {Agenda.attributes.titre1}
                          </MDBCardTitle>
                          <MDBCardText className="agenda-txt">
                            <b>Heure :</b> {Agenda.attributes.heure}
                            <br />
                            <b>Durée :</b> {Agenda.attributes.duree}
                            <br />
                            <b>Thème :</b> {Agenda.attributes.theme}
                            <br />
                          </MDBCardText>
                        </MDBCardBody>
                        {/* <MDBCardFooter>
                          <div class="interior">
                            <a class="agenda-btn" href="#open-modal1">
                              En savoir plus
                            </a>
                          </div>
                          <div id="open-modal1" class="modal-window">
                            <div>
                              <a
                                href="#agend-slider"
                                title="Fermer"
                                class="modal-close"
                              >
                                <i className="fas fa-close"></i>
                              </a>

                              <h1>{Agenda.attributes.titre}</h1>
                              <p>
                                <b>Description atelier: </b>
                                {Agenda.attributes.description}
                                <br />
                                <b>Lieu:</b>
                                {Agenda.attributes.lieu}
                                <br />
                                <b>Temps:</b> {Agenda.attributes.temps}
                                <br />
                                <b>Tranche d'âge:</b> {Agenda.attributes.age}
                                <br />
                                <b>Se Concentrer:</b> {Agenda.attributes.seConcentrer}
                                <br />
                                <b>Conditions:</b> {Agenda.attributes.conditions}{" "}
                                <br />
                                <b>Matériaux:</b> {Agenda.attributes.materiaux}
                                <br />
                                <b>Participants:</b>
                                {Agenda.attributes.participants}
                                <br />
                                <b>Prix: </b>
                                {Agenda.attributes.prix}{" "}
                              </p>
                              <br />
                              <div>
                                <small>Consulter pour l'inscription 👇</small>
                              </div>
                              <a
                                className="inscris"
                                href="/agenda"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Inscription!
                              </a>
                            </div>
                          </div>
                        </MDBCardFooter> */}
                      </MDBCard>
                    </MDBCol>
                  </Col>
                ))}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
