import React from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const TermsConditonSection = () => {
  return (
    <>
      <div className="BreadCrumb">
        <Container>
          <div className="Bread-Crumb">
            <h1>
              <i className="fas fa-gears fa-spid fa-color-r"></i>Mentions légales
            </h1>
            <div className="Bread-Crumb-text">
              <Link to="/home" className="home-page">
                Accueil
              </Link>
              <h6>/</h6>
              <Link to="/terms" className="Current-page">
                Mentions légales
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="TermC Title" style={{ paddingTop: "50px" }}>
          <div className="TermsContent">
            <h4>
              <span className="number">1. </span>Éditeur du site
            </h4>
            <p>
              {" "}
              Le site{" "}
              <a target="_blank" href="http://www.cybergrange.eu">
                {" "}
                www.cybergrange.eu{" "}
              </a>
              est édité par le Centre social et culturel du Neuhof, dont le
              siège social est situé au 8 rue Georges Epstein, BP 10016, 67026
              STRASBOURG.
            </p>
          </div>
          <div className="TermsContent">
            <h4>2. Renseignements généraux</h4>
            <p>
              Numéro SIREN : <span className="bpd-text">322 150 731</span>{" "}
              <br />
              Numéro SIRET :{" "}
              <span className="bpd-text">322 150 731 000 22</span>
              <br />
              Code APE:{" "}
              <span className="bpd-text">
                Action sociale sans hébergement n.c.a. (8899B)
              </span>
              <br />
              Numéro de téléphone :{" "}
              <span className="bpd-text">03 88 39 09 00</span>
              <br />
              Adresse de messagerie :{" "}
              <span className="bpd-text"><a href='mailto:contact@cscneuhof.eu'>contact@cscneuhof.eu</a></span>
              <br />
              Hébergeur du site : <span className="bpd-text">Heroku</span>
              <br />
            </p>
          </div>
          <div className="TermsContent">
            <h4>3. Site</h4>

            <p>
            Directeur de la publication :{" "}
              <span className="bpd-text">Jérôme Tricomi</span> <br />
              Référent RGPD :{" "}
              <span className="bpd-text">Anwar Ali Kediye " anwar.ali-kediye@cybergrange.eu "</span> <br />
              Création :{" "}
              <span className="bpd-text">
                <a href="https://www.linkedin.com/in/zahra-ahmadi-119715124/">
                  Zahra Ahmadi
                </a>
              </span>{" "}
            </p>
          </div>
          <div className="TermsContent">
            <h4>4. Conditions d'utilisation</h4>

            <p className="j-p">
              Les informations contenues dans ce site sont soumises à la loi
              française et sont fournies "telles quelles" sans garanties
              d'aucune sorte, ni explicites, ni tacites. Ces informations
              peuvent contenir des inexactitudes techniques ou des erreurs
              typographiques. Elles sont non contractuelles et sujettes à
              modification sans préavis.
            </p>
          </div>
          <div className="TermsContent">
            <h4>5. Utilisation des données et Copyright</h4>
            <p>
              Les données et les informations présentes sur le site internet
              www.cybergrange.eu sont mises à disposition
            </p>
            <p>
              Toute utilisation des données ou des informations provenant du
              site internet de la CyberGrange doit obligatoirement mentionner la
              CyberGrange en tant que source de l’information.
            </p>
            <p>
              Le logo de la CyberGrange est un modèle déposé et protégé. Toute
              utilisation nécessite une autorisation écrite formelle du
              responsable de la CyberGrange. Cependant, il existe une dérogation
              lorsque le logo est utilisé pour faire un lien sur notre site.
            </p>
          </div>
          <div className="TermsContent">
            <h4>6. Les cookies</h4>
            <p>
              Lorsque vous vous connectez sur notre site, nous sommes
              susceptibles d’installer divers cookies sur votre terminal. Les
              cookies que nous émettons nous permettent :
            </p>
            <p>de maintenir le bon fonctionnement du site</p>
            <p>
              d'enregistrer et adapter les fonctionnaptés du Site en fonction de
              vos préférences de navigation (identification, s'adapter aux types
              d'appareils utipsés)
            </p>
            <p>
              de gérer l'acceptation et la durée de conservation des cookies
            </p>
            <p>
              Conformément à la réglementation, les cookies ont une durée de vie
              maximum de 13 mois.
            </p>
            <p>
              Les cookies analytics : ils nous permettent de suivre les
              audiences de notre site et de connaître votre navigation sur notre
              site (cookies Google Analytics) et de générer des données
              statistiques quant aux utilisations du Site. Cela nous permet
              d’améliorer la performance du site.
            </p>
            <p>
              Les cookies des réseaux sociaux : en naviguant sur notre site,
              vous avez la possibilité de cliquer sur les boutons « réseaux
              sociaux » afin de consulter nos profils Facebook. Ces applications
              tierces sont susceptibles de déposer des cookies pour vous
              proposer de la publicité ciblée. En cliquant sur l’icône
              correspondante au réseau social, ce dernier est susceptible de
              vous identifier. Si vous êtes connectés au réseau social lors de
              votre navigation sur notre Site, les boutons de partage permettent
              de relier les contenus consultés à votre compte utilisateur.
            </p>
          </div>
          <div className="TermsContent">
            <h4>7. Propriété intellectuelle</h4>
            <p>
              Conformément au code de la Propriété Intellectuelle et plus
              généralement à tous les accords comportant des dispositions
              relatives au droit d'auteur, la reproduction partielle ou totale
              de textes, d'images ou d'illustrations, y compris pour les
              documents téléchargeables par les visiteurs, sont interdites sans
              autorisation préalable de l'éditeur ou de tout ayant droit.
            </p>
            <p>
              Toute vipation des droits d’auteur est sanctionnée par l’article
              L.335-2 du Code de la Propriété Intellectuelle, avec une peine
              encourue de 2 ans d’emprisonnement et de 150000€ d’amende.
            </p>
          </div>
          <div className="TermsContent">
            <h4>8. Crédits</h4>
            <p>
              Certains visuel présents sur le site de la CyberGrange sont en
              licence gratuite, Designed by{" "}
              <a href="https://www.freepik.com/">Freepik.</a>
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};
