import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Container, Row, Col, Image } from "react-bootstrap";
import Iframe from "react-iframe";
import "aos/dist/aos.css";
import axios from "axios";
import { Link, Text } from "react-router-dom";
import img1 from "../../../assets/Gallery/MIDE.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";

const url = process.env.REACT_APP_BASE_URL;

const validateSchema = Yup.object({
  nom: Yup.string().typeError("Requis").required("Requis"),
  sujet: Yup.string().typeError("Requis").required("Requis"),
  email: Yup.string()
    .typeError("Requis")
    .email("Email invalide")
    .required("Requis"),
  telephone: Yup.string()
    .typeError("Requis")
    .required("Requis")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Numéro de téléphone invalide"
    ),
  message: Yup.string()
    .typeError("Requis")
    .required("Requis"),
});

export const ContactDetail = () => {

  const formik = useFormik({
    initialValues: {
      nom: "",
      email: "",
      sujet: "",
      telephone: "",
      message: "",
    },
    validationSchema: validateSchema,

    onSubmit: async (values, {resetForm}) => {
      try{
        const data = await axios.post(`${url}/api/contacts`, {
          data: { ...values },
        });
        alert('Soumis avec succès!');
        resetForm();
        console.log("contact", values);
      }
      catch(e){

      }
      }
  });

  return (
    <>
      <div className="BreadCrumb ">
        <Container>
          <div className="Bread-Crumb">
            <h1>
              <i className="far fa-address-book fa-color-r"></i> Accès & Contact
            </h1>
            <div className="Bread-Crumb-text">
              <Link to="/home" className="home-page">
                Accueil
              </Link>
              <h6>/</h6>
              <Link to="/contact" className="Current-page">
                Accès & Contact
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container>
          <br></br>
          <br></br>
          <br></br>
          <div className="Title">
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className="contact">
                    <div className="Contact-title">
                      <h2>Contact</h2>
                      <p>
                        Vous avez une question ? Vous souhaitez nous rencontrer
                        ? Vous avez un projet dans le champ du numérique ?
                        Faites-le savoir en complétant ce formulaire :
                      </p>
                    </div>
                    <div className="Contact-Social">
                      <div className="Contact-Social-box">
                        <div className="Contact-icon">
                          <i className="far fa-envelope"></i>
                        </div>
                        <div className="Conact-icon-detail">
                          <h4>E-Mail</h4>
                          <div>
                            <span className="Contact-Social-text"></span>
                            <span className="Contact-no-mail">
                              <a href="mailto:contact@cybergrange.eu"><b>contact@cybergrange.eu</b></a>
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="Contact-Social-box">
                        <div className="Contact-icon">
                          <i className="fas fa-phone-alt"></i>
                        </div>
                        <div className="Conact-icon-detail">
                          <h4>Contact</h4>
                          <div>
                            <span className="Contact-no-mail">
                              <a href="tel:+33771894687"><b>07.71.89.46.87</b></a>
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="Contact-Social-box">
                        <div className="Contact-icon">
                          <i className="far fa-clock"></i>
                        </div>
                        <div className="Conact-icon-detail">
                          <h4>Heures d'ouverture</h4>
                          <div>
                            <div>
                              <span className="Contact-Social-text">
                                <b>Lundi - Vendredi :</b>
                              </span>
                              <span className="Contact-Social-text">
                              <b>09.00 - 17.30</b>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className="form-box">
                    <div className="Contact-form">
                      <h2>Prêt à commencer?</h2>
                      <p>Les champs requis sont indiqués *</p>
                      <Row>
                        <Col xl={6} lg={6} sm={6}>
                          <div className="Form-group">
                            <span>
                              <input
                                type="text"
                                name="nom"
                                placeholder="Nom et Prénom *"
                                onChange={formik.handleChange}
                                value={formik.values?.nom || ""}
                              />
                            </span>
                            {formik.touched?.nom && formik.errors?.nom && (
                              <div className="alert alert-danger p-1 mt-1">
                                {formik.errors?.nom}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xl={6} lg={6} sm={6}>
                          <div className="Form-group">
                            <span>
                              <input
                                name="email"
                                type="email"
                                placeholder="Email *"
                                onChange={formik.handleChange}
                                value={formik.values?.email || ""}
                              />
                            </span>
                            {formik.touched?.email && formik.errors?.email && (
                              <div className="alert alert-danger p-1 mt-1">
                                {formik.errors?.email}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xl={6} lg={6} sm={6}>
                          <div className="Form-group">
                            <span>
                              <input
                                type="text"
                                name="telephone"
                                onChange={formik.handleChange}
                                value={formik.values?.telephone || ""}
                                placeholder="Téléphone *"
                              ></input>
                            </span>
                            {formik.touched?.telephone &&
                              formik.errors?.telephone && (
                                <div className="alert alert-danger p-1 mt-1">
                                  {formik.errors?.telephone}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col xl={6} lg={6} sm={6}>
                          <div className="Form-group">
                            <span>
                              <input
                                type="text"
                                name="sujet"
                                onChange={formik.handleChange}
                                value={formik.values?.sujet || ""}
                                placeholder="Sujet *"
                              />
                            </span>
                            {formik.touched?.sujet && formik.errors?.sujet && (
                              <div className="alert alert-danger p-1 mt-1">
                                {formik.errors?.sujet}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xl={12} lg={12} sm={12}>
                          <div className="Form-group Contact-textarea">
                            <span>
                              <textarea
                                type="text"
                                name="message"
                                placeholder="Rédigez votre message....  *"
                                onChange={formik.handleChange}
                                value={formik.values?.message || ""}
                              />
                            </span>
                            {formik.touched?.message &&
                              formik.errors?.message && (
                                <div className="alert alert-danger p-1 mt-1">
                                  {formik.errors?.message}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col xl={12} lg={12} sm={12}>
                          <div>
                            <Button type="submit" className="btn-box-contact">
                              Envoyer le message
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Container>
        <Container>
          {/* <Container data-aos="fade-in"> */}
          <br></br>
          <br></br>
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className="Contact-title">
                <h2>Comment venir ?</h2>
                <p>
                  2 Rue du Commandant François, 67100 Strasbourg
                  Neuhof
                </p>
                <h4 style={{ marginTop: "30px" }}>Transport en commun</h4>
                <ul>
                  <li>Tram C - arrêt Rodolphe Reuss</li>
                  <li>Bus 24 - arrêt Neuhof Rodolphe Reuss</li>
                  <li> Bus 40 depuis le parking relais Elsau vers Neuhof</li>
                </ul>
                <h4 style={{ marginTop: "30px" }}>Vélo</h4>
                <ul>
                  <li>Pistes cyclables à proximité</li>
                  <li>Arceaux à vélo devant la salle</li>
                </ul>
                <h4 style={{ marginTop: "30px" }}>Voiture</h4>
                <ul>
                  <li>
                    Depuis le centre-ville de Strasbourg: Place de l'Etoile,
                    direction route du Polygone, suivre l'avenue du Neuhof, puis
                    avenue Jacqueline Auriol et prendre rue du Commandant
                    François.
                  </li>
                  <li>
                    Par l'autoroute : Sortie Illkirch centre commercial, suivre
                    avenue de Colmar, prendre la rue de Provence, continuer tout
                    droit rue de l'Abbé de l'Epée, allée Jacqueline Auriol, et
                    prendre rue du Commandant François.
                  </li>
                  <li>Parking gratuit à proximité.</li>
                </ul>
              </div>
            </Col>
            <Col className="ContactImg" xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className="contact-img">
                <Image className="Add-img border-img" src={img1} />
                <p className="Add-text">MIDE Strasbourg</p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="Map">
          <Iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2641.2982135933457!2d7.7688323!3d48.546679499999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796c9660e912251%3A0xfec12d3417c996ba!2s2%20Rue%20du%20Commandant%20Fran%C3%A7ois%2C%2067100%20Strasbourg!5e0!3m2!1sen!2sfr!4v1666681125009!5m2!1sen!2sfr"
            frameBorder="0"
            height="450"
            target="_blank"
          />
        </div>
      </div>
    </>
  );
};