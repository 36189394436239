
import img1 from "../../../assets/Gallery/G1.png"
import img2 from "../../../assets/Gallery/G2.jpg"
import img3 from "../../../assets/Gallery/G3.png"
import img4 from "../../../assets/Gallery/G4.png"
import img5 from "../../../assets/Gallery/G5.png"
import img6 from "../../../assets/Gallery/G6.png"



export const RessourceData = [
    {
        id: 1,
        link: 'https://movilab.org/wiki/Accueil',
        image: img1,
        title: "Movilab",
        text:  "Explorez les ressources de Movilab",
        p:      'Le wiki des Tiers-Lieux ! Vous pouvez trouver en accès libre des documentations sur de nombreuses actions réalisées dans des lieux par exemple, des partages d’expériences, etc .. Vous pouvez également vous aussi documenter dessus afin d’enrichir movilab et polliniser les savoirs.'
    },
    {
        id: 2,
        link: 'https://tierslieux.anct.gouv.fr/fr/labo-accueil/',
        image: img2,
        title: "Labo des Tiers-Lieux",
        text:  "Découvrez le Labo des Tiers-Lieux",
        p: 'Le Labo est un média ressource destiné à valoriser la connaissance existante, produire de nouveaux contenus et se faire l’écho de l’actualité de la recherche sur les tiers-lieux. Chaque mois retrouvez des brèves, des articles thématiques, des cartes blanches et des fiches de lecture autour d’un thème'
    },
    {
        id: 3,
        link: 'https://rfflabs.fr' , 
        image: img3,
        title: "RFFLabs",
        text:  "Explorez les ressources RFFLabs",
        p: ' créée en 2015 par un collectif de Makers Français, aujourd’hui c’est une association nationale qui compte des dizaines de membres qui coopèrent ensemble sur des sujets très variés'
    },
    {
        id: 4,
        link:'https://francetierslieux.fr',
        image: img4,
        title: "France Tiers Lieux",
        text:  "Explorez les ressources France Tiers Lieux",
        p: 'L’association nationale des Tiers-lieux en France, pour ne rien manquer de l’actualité nationale. Vous pouvez trouver une cartographie, des documents pour vous aider sur la création de votre Tiers-Lieu, les aides gouvernementales, etc ...'
    },
    {
        id: 5,
        link:'https://www.makery.info/',
        image: img5,
        title: "Makery",
        text:  "Explorez les ressources de Makery",
        p: 'Makery est un média d’information en ligne fondé par Digital Art International en juin 2014 et dirigé par Anne-Cécile Worms. Il vise à couvrir le dynamisme et à  diffuser des informations sur les communautés créatives et la scène des labs, fablabs, makerspaces, hackerspaces, medialabs, living labs, biohacklabs et autres tiers-lieux'
    },
    {
        id: 6,
        link:'https://www.lesbonsclics.fr/fr/formation-numerique',
        image: img6,
        title: "Les Bons Clics",
        text:  "Explorez les ressources de Les Bons Clics",
        p: 'La platepforme “Les Bons Clics”, créée par WeTechCare, propose des ressources pédagogiques et des parcours de formation pour découvrir le numérique ou pour s’outiller en tant que médiateur-trice:'
    },
]