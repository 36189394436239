import React from 'react'
import "../../../theme/css/Contact.css"
import { ContactDetail } from './ContactDetail'

export const Contact = () => {
    return (
        <>
            <ContactDetail/>
        </>
    )
}
