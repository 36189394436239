import React from "react";
import "../../../theme/css/Ressource.scss";
import { RessourceArea } from "./RessourceArea";

export default function Ressource() {
  return (
    <>     
        <RessourceArea/>
    </>
  );
}
