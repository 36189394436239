import React, { useEffect } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { GrFacebookOption } from "react-icons/gr";
import { Link } from "react-router-dom";
import '../../../theme/css/Team.css'
import axios from "axios";
import { useState } from "react";
const  url = process.env.REACT_APP_BASE_URL;

export const Equipe = () => {
  const [employees, setEmployees] = useState([])
  const getEmployee = async() => {
    const data = await axios.get(`${url}/api/employees?sort[0]=id&populate=image`)
    setEmployees(data.data.data)
    console.log(data)
  }
  useEffect(()=>{
    getEmployee()
  } , [])
  return (
    <>
      <div className="BreadCrumb" >
      <Container>
          <div className="Bread-Crumb">
              <h1>Une équipe à votre service</h1>
              <p style={{paddingTop:'15px'}}>Depuis le 2 juillet 2020, L'équipe de la CyberGrange s'étoffe !</p>
          </div>
      </Container>
      </div>
      <Container>
      <Row className="team" style={{paddingTop:'50px'}}>
      <div class="container">
        <div class="row">
          {employees?.length>0 && employees.map((employee)=>(
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="our-team">
              <div class="picture">
                <img class="img-fluid" src={employee?.attributes?.image.data?.attributes?.url}/>
              </div>
              <div class="team-content">
                <h3 class="fname">{employee?.attributes?.fullname}</h3>
                <h4 class="job">{employee?.attributes?.title}</h4>
              </div>
              <ul class="social">
                <li><a href={`mailto:${employee?.attributes?.email}`} target="_blank"  class="fa fa-envelope" aria-hidden="true"></a></li>
                <li><a href={employee?.attributes?.linkedin} target="_blank" class="fa fa-linkedin" aria-hidden="true"></a></li>
                <li><p aria-hidden="true"> {employee?.attributes?.phone}</p></li>
              </ul>
            </div>
          </div>
          ))}
          
          </div>
        </div>
      </Row>
      </Container>
    </>
  );
};