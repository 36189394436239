import React from "react";
import { Routes, Route } from "react-router-dom";
import Ressource from "../components/pages/Ressource/Ressource";
import { Contact } from "../components/pages/Contact/Contact";
import { Qui } from "../components/pages/A-propos/Qui";
import { Equipe } from "../components/pages/A-propos/equipe";
import { Agenda } from "../components/pages/A-propos/agenda";
import { Actualites } from "../components/pages/Event/Actualites";
import { Home } from "../components/pages/Home/Home";
import { AboutUs } from "../components/pages/Page/About/AboutUs";
import { FQA } from "../components/pages/Page/FAQ/FQA";
import { Apprendre } from "../components/pages/Services/apprendre";
import { Creer } from "../components/pages/Services/creer";
import { Former } from "../components/pages/Services/se-former";
import { Recycler } from "../components/pages/Services/recycler";
import { TermsCondition } from "../components/pages/Page/Terms_Condition/TremsCondition";
import { Soutiennent } from "../components/pages/Course/SingleCourse/Soutiennent";
// import { ForgotPwd } from "../components/LoginRigester/Forgotpwd/ForgotPwd";
// import { Login } from "../components/LoginRigester/Login/Login";
// import { RegisterMain } from "../components/LoginRigester/Register/RegisterMain";
import News from "../components/pages/actualites/[...ActualiteId]";

export default function Index() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/apprendre" element={<Apprendre />} />
        <Route path="/creer" element={<Creer />} />
        <Route path="/se-former" element={<Former />} />
        <Route path="/recycler" element={<Recycler />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/faq" element={<FQA />} />
        <Route path="/terms" element={<TermsCondition />} />
        <Route path="/Soutiennent" element={<Soutiennent />} />
        <Route path="/Actualites" element={<Actualites />} />
        <Route path="/actualites/:ActualiteId" element={<News />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/qui" element={<Qui />} />
        <Route path="/equipe" element={<Equipe />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/ressource" element={<Ressource />} />
        {/* <Route path="/register" element={<RegisterMain />} /> */}
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/forgotpwd" element={<ForgotPwd />} /> */}
      </Routes>
    </div>
  );
}