import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap-v5";
import img1 from "../../../../assets/OnlineLearing/OLearing.png";
import AOS from "aos";
import "aos/dist/aos.css";

export const Learing = () => {
  useEffect(() => {
    AOS.init({
      duration: 3000,
      once: true,
    });
  });
  return (
    <>
      <div className="Title">
        <Container>
          <div className="Title">
            <Row className="OLearing-section">
              <Col xl={7} lg={7} md={7} sm={7}>
                <div
                  className="Online-learnig-Content text-align-center"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="1500"
                  data-aos-once="true"
                >
                  <h4 className="h-left">~ Qui sommes-nous ?</h4>
                  <h2>Le Projet</h2>
                  <p>
                    Tiers lieu dédié à l'inclusion numérique au coeur du QPV
                    Neuhof/Meinau, la Cybergrange est tout à la fois lieu de
                    formation tous azimuts, point d’accès aux droits, espaces de
                    loisirs numériques et Fablab solidaire.
                  </p>
                  <p>
                    Elle a été imaginée par un collectif de partenaires : le
                    collège Solignac, l’équipe de <a className="link" target="_blank" href="https://www.jeep.asso.fr/">prévention</a> spécialisée de la
                    Jeep, FACE Alsace, Form'maker, l'école de formation de
                    développeurs web “Access code School”, l'entreprise
                    Neuronality, Lupovino, le projet FOCALE PIC 100% inclusion
                    pour accompagner 400 personnes à la recherche d'un emploi,
                    l’organisme de formation CPCV Est, la <a className="link" target="_blank" href="https://associationlaresu.com/">RESU </a>.
                  </p>
                  <br />
                  <a href="../../Qui" className="link" target="_blank">
                    En savoir plus ....
                  </a>
                </div>
              </Col>
              <Col xl={5} lg={5} md={4} sm={4}>
                <img src={img1} alt="OLearing" className="mx-auto img-fluid" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
