import React from "react";
import "../../../theme/css/Former.css";

export const Former = () => {
  return (
    <>
      <div className="section-container section-half-background-image-container">
        <div className="image-column col-lg-6"></div>
        <div className="container row">
          <div className="row">
            <div className="col-lg-6"></div>
            <div className="text-column col-lg-6 ml-lg-5">
              <h2>Se former</h2>
              <h3>Formation</h3>
              <p>
                La Cybergrange a créé, avec plusieurs partenaires, un organisme
                de formation : Territoire Accompagnement Conseils (TAC). Nous
                organisons régulièrement des formations qualifiantes, sur des
                parcours allant de quelques jours à quelques mois.{" "}
              </p>
              <p>
                Comme dans toutes nos activités, les parcours de formation sont
                pensés en partant de la pratique pour apprendre, avec une
                pédagogie active.{" "}
              </p>
              <p>
                Pour en savoir plus et découvrir toute l’offre de formation,
                rendez-vous sur le site{" "}
                <b>
                </b>
              </p>
              <a
                className="btn cbtn"
                title="voir le site"
                href="https://tacompetence.eu"
                target="_blank">
                Tacompetence.eu
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
