import React, { useState, useEffect } from "react";
import "../../../../theme/css/Services.scss";
import img1 from "../../../../assets/Services/img1.jpg";
import img2 from "../../../../assets/Services/img2.png";
import img3 from "../../../../assets/Services/img3.png";
import img4 from "../../../../assets/Services/img4.png";



export const Services = () => {

  return (
    <>
        <section id='timeline'>
        <h1> Nos pôles</h1>
        <p class="leader"><b>Découvrez tous les services que nous offrons</b></p>
        <div class="demo-card-wrapper">
            <div class="demo-card demo-card--step1">
                <div class="head">
                    <div class="number-box">
                    <h4> Apprendre </h4>
                    </div>
                </div>
                <div class="body">
                    <p> <b>Médiation numérique :</b> La CyberGrange vous accompagne jusqu'à l'autonomie dans les usages quotidiens des technologies, services et médias numériques.</p>
                    <img src={img1} alt="Graphic" />
                </div>
            </div>

            <div class="demo-card demo-card--step2">
                <div class="head">
                    <div class="number-box">
                    <h4> Créer</h4>
                    </div>
                </div>
                <div class="body">
                    <p><b>Fablab :</b> Le fablab de la Cybergrange est un espace de création et de réparation, équipé de machines à commande numérique et d'outils classiques.</p>
                    <img src={img2} alt="Graphic" />
                </div>
            </div>

            <div class="demo-card demo-card--step3">
                <div class="head">
                    <div class="number-box">
                    <h4> Réparer</h4>
                    </div>
                </div>
                <div class="body">
                    <p><b>Réemploi :</b> La CyberGrange abrite dans ses locaux, un espace de réparation. Cet atelier est composé d'un pôle de réparation textile, électronique, électroménager, vélo et petit mobilier.</p>
                    <img src={img3} alt="Graphic" />
                </div>
            </div>

            <div class="demo-card demo-card--step4">
                <div class="head">
                    <div class="number-box">
                    <h4> Se former</h4>
                    </div>
                </div>
                <div class="body">
                    <p><b>Formation : </b>La Cybergrange a créé, avec plusieurs partenaires, un organisme de formation : (TAC) Territoire Accompagnement Conseils.</p>
                    <img src={img4} alt="Graphic" />
                </div>
            </div>
        
        </div>
    </section>
    </>
  );
};
