import React, { useEffect } from "react";
import img26 from "../../../../assets/Partner/mide.png";
import '../../../../theme/css/Soutiennent.css' 
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
const  url = process.env.REACT_APP_BASE_URL;
 

export const Soutiennent = () => {
  const [soutiennents, setSoutiennents] = useState([])
  const getSoutiennent = async() => {
    const data = await axios.get(`${url}/api/soutiennents?sort[0]=id&populate=image`)
    setSoutiennents(data.data.data)
    console.log(data)
  }
  useEffect(()=>{
    getSoutiennent()
  } , [])
    return (
        <>
        <div className="BreadCrumb"  >
          <div className="Bread-Crumb" style={{paddingTop:'0px'}}>
              <h1><i class="fa fa-handshake fa-color-r"></i> Ils nous soutiennent</h1>
              <div className="Bread-Crumb-text">
              <Link to="/home" className="home-page">
              Accueil
              </Link>
              <h6>/</h6>
              <Link to="/Soutiennent" className="Current-page">
              Ils nous soutiennent
              </Link>
              </div>
          </div>  
        </div>
        <div className="container">
        <div class="logo-container">
        {soutiennents?.length>0 && soutiennents.map((Soutiennent)=>(
        <img src={Soutiennent?.attributes?.image.data?.attributes?.url} alt="Logo 1" />
         ))}
        </div>
        </div>
        <div className="container">
          <p style={{paddingTop:'50px'}} className='center-p Course-Card-p'>Les locaux de a CyberGrange se trouvent à la Maison de l'insertion et du développement économique.</p>
          <img class='center image-d' src={img26}  alt="MIDE" />
        </div>
        </>
    )
}
