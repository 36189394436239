import React, { useState } from "react";
import { Navbar, Button, Nav, NavDropdown } from "react-bootstrap";
import logo from "../../../src/assets/Logo/Black-logo.png";
import logoNoText from "../../../src/assets/Logo/White-Logo.png";
import { Link } from "react-router-dom";
import "../../theme/css/index.css";

export default function Header() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownC, setShowDropdownC] = useState(false);
  var className = "inverted";

  const [addClass, setAddClass] = useState("");
  var scrollTrigger = 135;

  window.onscroll = function () {
    setAddClass();
    if (
      window.scrollY >= scrollTrigger ||
      window.pageYOffset >= scrollTrigger
    ) {
      document.getElementsByTagName("header")[0].classList.add(className);
    } else {
      document.getElementsByTagName("header")[0].classList.remove(className);
    }
  };

  const [isOpen, setIsopen] = useState(false);
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  return (
    <>
      <header className="">
        <div className="Header menu-fixed container-fluid">
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className="container d-flex justify-content-between align-items-center justify-content-lg-center "
          >
            <Navbar.Brand href="/" className="nav-logo">
              <img
                src={logo}
                alt="logo"
                className="img-fluid Black-logo d-none d-lg-inline"
              />
              <img
                src={logo}
                alt="logo"
                className="img-fluid White-logo d-lg-none"
              />
            </Navbar.Brand>
            <Button
              className="btn-navbar navbar-toggle"
              onClick={ToggleSidebar}
            >
              <i className="fa fa-bars" aria-hidden="true"></i>
            </Button>
            <div className={`sidebar ${isOpen === true ? "active" : " "}`}>
              <div id="mySidenav" className="sidenav menu-vertical">
                <div className="close-nav hidden-md hidden-lg hidden-xl">
                  <div>
                    <span>La <b>Cyber</b>Grange</span>
                  </div>
                  <div>
                    <Button
                      className="closebtn pull-right"
                      onClick={ToggleSidebar}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </Button>
                  </div>
                </div>
                <Nav
                  as={"ul"}
                  className="justify-content-lg-center align-items-lg-center menu-responsive"
                >
                  <Nav.Link as={"li"} className="nav-links">
                    <Link to="/home">Accueil</Link>
                  </Nav.Link>
                  <NavDropdown
                    as={"li"}
                    title="Nos pôles"
                    id="collasible-nav-dropdown"
                    show={showDropdown}
                    onMouseLeave={() => setShowDropdown(false)}
                    onMouseOver={() => setShowDropdown(true)}
                  >
                    <NavDropdown.Item as={"div"} href="#apprendre">
                      <Link to="/apprendre">Apprendre</Link>{" "}
                    </NavDropdown.Item>

                    <NavDropdown.Item as={"div"} href="#creer">
                      <Link to="/creer">Créer</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item as={"div"} href="#recycler">
                      <Link to="/recycler">Réparer</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item as={"div"} href="#se-former">
                      <Link to="/se-former">Se former</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={"div"} href="#">
                      <Link to="https://www.calameo.com/read/00714167268eda3a56815">Notre catalogue d’activités</Link>
                    </NavDropdown.Item>
                  </NavDropdown>

                  <Nav.Link as={"li"} className="nav-links" href="#agenda">
                    <Link to="/agenda">Agenda</Link>
                  </Nav.Link>

                  <Nav.Link as={"li"} className="nav-links" href="#Actualites">
                    <Link to="/Actualites">Actualités</Link>
                  </Nav.Link>

                  <Nav.Link as={"li"} className="nav-links" href="#ressource">
                    <Link to="/ressource">Ressources</Link>
                  </Nav.Link>

                  <NavDropdown
                    as={"li"}
                    title="A propos"
                    id="collasible-nav-dropdown"
                    show={showDropdownC}
                    onMouseLeave={() => setShowDropdownC(false)}
                    onMouseOver={() => setShowDropdownC(true)}
                  >
                    <NavDropdown.Item as={"div"} href="#qui">
                      <Link to="/qui">Qui sommes-nous</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item as={"div"} href="#equipe">
                      <Link to="/equipe">L'équipe</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={"div"} href="#Soutiennent">
                      <Link to="/Soutiennent">Ils nous soutiennent</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={"div"} href="#terms">
                      <Link to="/terms">Mentions légales</Link>
                    </NavDropdown.Item>
                  </NavDropdown>

                  <Nav.Link as={"li"} className="nav-links" href="#contact">
                    <Link to="/contact">Contact</Link>
                  </Nav.Link>
                </Nav>
              </div>
            </div>
            <div
              className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
              onClick={ToggleSidebar}
            ></div>
          </Navbar>
        </div>
      </header>
    </>
  );
}