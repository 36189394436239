import React, {useEffect, useRef, useState } from 'react';
import '../../../theme/css/Actualites.scss'
import axios from 'axios';
import { Container } from 'react-bootstrap';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import ReactMarkdown from "react-markdown";
import 'react-quill/dist/quill.snow.css';
const  url = process.env.REACT_APP_BASE_URL;


export default function News () {    
    const params = useParams()
        console.log(params)
    const [Actualite, setActualite] = useState()
    const getActualite = async(id) => {
        const {data} = await axios.get(`${url}/api/Actualites/${id}?sort[0]=id&populate=*`)
        setActualite(data.data)
        console.log("dddd",data.data)
    };
 
    useEffect(()=>{
        if(params?.ActualiteId){
            getActualite(params?.ActualiteId)
        }
    } , [params])

    return (
        <>
    {Actualite && 
            <div className='row'>
            <h4 className="acut-h2">{Actualite?.attributes.titre}</h4>
                <img className="acut-pic"
                src={Actualite?.attributes.image2?.data?.attributes.url}
                alt='can not load the photo'/>
                <div className="background"></div>
                <Container className='news-paragraph' >
                    <p className="acut-h3">{Actualite?.attributes.soustitre}</p>
                    <p className="acut-h4">{Actualite?.attributes.soustitre2}</p>
                    <ReactMarkdown className='line-break'>{Actualite?.attributes.description.replace(/\n/gi, "&nbsp; \n")}</ReactMarkdown>
                    <p style={{color:'#FF6700'}}><b>En savoir plus 👇 </b></p>
                    {/* <a href={Actualite?.attributes.link}><b>En savoir plus 👇 </b></a> */}
                    
                    <div className="center">
                    <iframe className="youtube-if" src={Actualite?.attributes.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>            
                </Container>
            </div>
        }
        </>
    );
}